.square-map-section {
  padding: 24px 0 60px 0;
  @media (min-width: 1200px) {
    padding: 80px 0;
  }
  .square-map-container {
    padding: 0 24px;
    max-width: 1200px;
    margin: 0 auto;
    @media (min-width: 1200px) {
      padding: 0;
    }
  }
  .square-map-title {
    font-family: samsungsharpsans-bold, sans-serif;
    font-size: 20px;
    line-height: 28px;
    color: #000;
    margin-bottom: 16px;
    @media (min-width: 1200px) {
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 24px;
    }
  }

  .square-map-area {
    display: grid;
    border-radius: 14px 16px;
    gap: 32px;
    @media (min-width: 1200px) {
      display: grid;
      grid-template-columns: 690px auto;
      padding: 42px;
      border-radius: 36px;
      gap: 62px;
      background: #f6f6f6;
    }
  }
  .square-map-box {
    position: relative;
    padding: 100px 16px;
    background: #f6f6f6;
    // margin: 0 -24px;
    border-radius: 16px;
    @media (min-width: 1200px) {
      padding: 40px 0;
      margin: initial;
      position: relative;
    }
    svg {
      width: 100%;
      height: initial;
    }
  }

  .firstSquare,
  .secondSquare,
  .thirdSquare {
    &.active {
      fill: rgba(117, 107, 241, 0.3);
      stroke-width: 1px;
      stroke: #756bf1;
    }
  }

  .fourthSquare.active {
    fill: rgba(150, 212, 82, 0.3);
    stroke-width: 1px;
    stroke: #96d452;
  }

  .square-map-list-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
    @media (min-width: 1200px) {
      gap: 48px;
    }
  }

  .square-map-list-title {
    font-family: samsungsharpsans-bold, sans-serif;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
    @media (min-width: 1200px) {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 12px;
    }
  }

  .square-map-list {
    display: grid;
    gap: 8px;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    & > div {
      display: flex;
      align-items: center;
      height: 48px;
      border-radius: 8px;
      background: #f6f6f6;
      color: #535353;
      padding: 0 12px;
      gap: 16px;
      font-family: 'SamsungOne-700', sans-serif;
      cursor: pointer;
      @media (min-width: 1200px) {
        height: 46px;
        background: #fff;
        padding: 0 8px;
      }
      svg {
        margin-left: auto;
      }
    }

    & > div.active {
      .list-label {
        background: #756bf1;
      }
      .list-text {
        color: #756bf1;
      }
    }
    .list-label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 6px;
      background: #535353;
      font-family: inherit;
      font-size: 16px;
      color: #fff;
      @media (min-width: 1200px) {
        width: 30px;
        height: 30px;
      }
    }
    .list-text {
      font-size: 16px;
      line-height: 20px;
      @media (min-width: 1200px) {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .square-map-list-group {
    & > div:last-child {
      .square-map-list {
        & > div.active {
          .list-label {
            background: #84d239;
          }

          .list-text {
            color: #84d239;
          }
        }
      }
    }
  }

  .common-popover {
    position: absolute;
    display: none;
    padding: 18px 24px;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
    .common-popover-contents {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:before {
        position: absolute;
        top: -14px;
        right: 32px;
        transform: translateY(-100%);
        width: 20px;
        height: 20px;
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none"><path d="M8.30303 1.14155C8.70181 0.582421 9.53257 0.582421 9.93134 1.14155L17.0831 11.1693C17.5552 11.8313 17.082 12.75 16.269 12.75H1.96539C1.15235 12.75 0.679143 11.8313 1.15124 11.1693L8.30303 1.14155Z" fill="white"/></svg>')
          no-repeat 0;
        background-size: 20px;
        content: '';
      }

      .common-popover-title {
        font-family: SamsungOne-700, sans-serif;
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
  .common-popover.first {
    top: 150px;
    right: 164px;
  }

  .common-popover.second {
    top: 150px;
    right: 280px;
  }

  .common-popover.third {
    top: 250px;
    right: 266px;
  }

  .common-popover.fourth {
    top: 150px;
    right: 120px;
    width: 538px;

    .common-popover-contents:before {
      right: 160px;
    }

    .common-popover-info {
      display: flex;
      flex-direction: column;
      height: 178px;

      .common-popover-text {
        margin-top: 6px;
        font-size: 14px;
        line-height: 20px;
        color: #7c7c7c;
        max-width: 288px;
      }

      .Link {
        margin-top: auto;
        max-width: 164px;
      }
    }

    .common-popover-figure {
      flex: 0 0 178px;
      img {
        width: 100%;
      }
    }
  }

  .common-popover.fourth02 {
    right: 272px;
    width: initial;
    .common-popover-contents:before {
      right: 10px;
    }
  }

  .common-popover.active {
    @media (min-width: 1200px) {
      display: block;
    }
  }

  .square-button {
    position: absolute;
    bottom: 16px;
    left: 16px;
    max-width: 122px;
  }
}
