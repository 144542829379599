/* slick slide space */
.main_slide {
  .slick-slide {
    margin: 0 12px;
  }

  .slick-list {
    margin: 0 -12px;
  }

  .slick-track {
    display: flex;
  }

  /* slick-slider, slick-arrow */
  .slick-slider {
    position: relative;
    padding-bottom: 80px;
    text-align: center;

    .slick-prev,
    .slick-next {
      width: 80px;
      height: 80px;
      background: rgba(0, 0, 0, 0.2) !important;
      border-radius: 50%;
      z-index: 2;
      transition: 0.4s !important;
    }

    .slick-prev {
      top: 50%;
      transform: translateY(-50%);
      left: -60px;
      transition: 0.2s;

      &.slick-disabled {
        opacity: 0;
        visibility: hidden;
        &:before {
          opacity: 0;
          visibility: hidden;
        }
        &:hover {
          opacity: 0;
          visibility: hidden;
        }
      }

      &:before {
        opacity: 0.8;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background-image: url('../../../public/images/ico-slide-arrow-prev.svg');
        background-repeat: no-repeat;
        background-position: center center;
        transition: 0.2s;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.4) !important;
      }
    }

    .slick-next {
      top: 50%;
      right: -60px;
      transform: translateY(-50%);

      &.slick-disabled {
        opacity: 0;
        visibility: hidden;
        &:before {
          opacity: 0;
          visibility: hidden;
        }
        &:hover {
          opacity: 0;
          visibility: hidden;
        }
      }

      &:before {
        opacity: 0.8;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background-image: url('../../../public/images/ico-slide-arrow-next.svg');
        background-repeat: no-repeat;
        background-position: center center;
        transition: 0.2s;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.4) !important;
      }
    }

    @media screen and (max-width: 1024px) {
      .slick-slider {
        text-align: center;
      }
    }

    @media screen and (max-width: 768px) {
      padding-bottom: 0;

      // .slick-slide:not(.slick-current) {
      /* Make slides other than the current one translucent */
      // opacity: 0.4;

      // .Text {
      //   opacity: 0;
      // }
      // }

      .slick-prev,
      .slick-next {
        width: 30px;
        height: 30px;
      }

      .slick-prev {
        left: 0;

        &::before {
          width: 30px;
          height: 30px;
          background-size: 27px 15px;
          background-size: contain;
        }
      }

      .slick-next {
        right: 0;

        &::before {
          width: 30px;
          height: 30px;
          background-size: contain;
        }
      }
    }
  }

  /* slick dots */
  .slick-dots {
    bottom: 0;
  }

  .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 14px;

    + li {
      margin-left: 0;
    }

    &.slick-active {
      margin-right: 36px;
    }
  }

  .slick-dots li button:before {
    width: 10px;
    height: 10px;
    font-size: 0;
    background-color: #d4dae2;
    border-radius: 100%;
    opacity: 1;
    transition: width 0.3s;
  }

  .slick-dots li.slick-active button:before {
    width: 32px;
    border-radius: 5px;
    background-color: #4387f7;
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    .slick-dots {
      bottom: 0;
    }

    .slick-dots li {
      width: 6px;
      height: 5px;
      margin: 0 8px;

      &.slick-active {
        margin-right: 24px;
      }
    }

    .slick-dots li button {
      width: 6px;
      height: 5px;
    }

    .slick-dots li button:before {
      width: 6px;
      height: 5px;
    }

    .slick-dots li.slick-active button:before {
      width: 20px;
      height: 5px;
    }
  }
}
