/* slick-slider, slick-arrow */
.clab_slide {
  /* slick slide space */
  .slick-slide {
    margin: 0 12px;

    @media screen and (max-width: 768px) {
      margin: 0 !important;

      &:first-child {
        margin-left: 0;
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &:not(.slick-current) {
        /* Make slides other than the current one translucent */
        opacity: 0.4;
      }
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-slider {
    position: relative;
    text-align: center;
    padding-bottom: 28px;
    margin-bottom: 0;

    .slick-list {
      margin: 0 -12px;
      @media screen and (max-width: 768px) {
        overflow: hidden !important;
      }
    }

    .slick-prev,
    .slick-next {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      z-index: 2;
      transition: 0.4s !important;
      backdrop-filter: blur(18px);
    }

    .slick-prev {
      top: 50%;
      transform: translateY(-50%);
      left: -40px;
      transition: 0.2s;

      &.slick-disabled {
        opacity: 0;
        visibility: hidden;
        &:before {
          opacity: 0;
          visibility: hidden;
        }
        &:hover {
          opacity: 0;
          visibility: hidden;
        }
      }

      &:before {
        opacity: 0.8;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background-image: url('../../../public/images/ico-techsquare-arrow-left.svg');
        background-repeat: no-repeat;
        background-position: center center;
        transition: 0.2s;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.4) !important;
      }
    }

    .slick-next {
      top: 50%;
      right: -40px;
      transform: translateY(-50%);

      &.slick-disabled {
        opacity: 0;
        visibility: hidden;
        &:before {
          opacity: 0;
          visibility: hidden;
        }
        &:hover {
          opacity: 0;
          visibility: hidden;
        }
      }

      &:before {
        opacity: 0.8;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background-image: url('../../../public/images/ico-techsquare-arrow-big.svg');
        background-repeat: no-repeat;
        background-position: center center;
        transition: 0.2s;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.4) !important;
      }
    }

    @media screen and (max-width: 1024px) {
      .slick-slider {
        text-align: center;
      }
    }

    @media screen and (max-width: 768px) {
      .slick-list {
        overflow: visible;
        margin: 0;
        .slick-track {
          padding-right: 12.5%;
        }
      }

      .slick-prev,
      .slick-next {
        width: 24px;
        height: 24px;
        top: calc(50% - 14px);
      }

      .slick-prev {
        left: -12px;

        &::before {
          width: 24px;
          height: 24px;
          background-image: url('../../../public/images/ico-techsquare-mo-left.svg');
        }
      }

      .slick-next {
        right: -12px;

        &::before {
          width: 24px;
          height: 24px;
          background-image: url('../../../public/images/ico-techsquare-mo-right.svg');
        }
      }
    }
  }

  /* slick dots */
  .slick-dots {
    bottom: 0;
  }

  .slick-dots li {
    width: 8px;
    height: 8px;
    margin: 0 10px;

    + li {
      margin-left: 0;
    }

    &.slick-active {
      margin-right: 10px;
    }

    @media screen and (max-width: 768px) {
      width: 6px;
      height: 6px;
      margin: 0;
    }
  }

  .slick-dots li button:before {
    width: 8px !important;
    height: 8px !important;
    font-size: 0;
    background-color: #d4dae2;
    border-radius: 100%;
    opacity: 1;
    transition: width 0.3s;

    @media screen and (max-width: 768px) {
      width: 6px !important;
      height: 6px !important;
      margin: 0 12px;
    }
  }

  .slick-dots li.slick-active button:before {
    width: 8px;
    border-radius: 5px;
    background-color: #2088fd;
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    // overflow: hidden;

    .slick-dots {
      bottom: 0;
      left: 0;
    }

    .slick-dots li {
      width: 6px;
      height: 6px;
      margin: 0 8px;
      // margin-left: 0;

      &.slick-active {
        margin-right: 8px;
      }
    }

    .slick-dots li button {
      width: 6px !important;
      height: 6px !important;
      padding: 0 !important;
    }

    .slick-dots li button:before {
      width: 6px;
      height: 6px;
      margin: 0;
    }

    .slick-dots li.slick-active button:before {
      width: 6px;
      height: 6px;
      margin-left: 0;
    }
  }
}
