.static-map-section {
  .static-map-container {
    padding: 0 24px;
    max-width: 1200px;
    margin: 0 auto;
    @media (min-width: 1200px) {
      padding: 0;
    }
  }
  .static-map-title {
    font-family: samsungsharpsans-bold, sans-serif;
    font-size: 20px;
    line-height: 28px;
    color: #000;
    margin-bottom: 16px;
    @media (min-width: 1200px) {
      font-size: 32px;
      line-height: 42px;
      margin-bottom: 24px;
    }
  }

  .map-img {
    width: 100%;
  }

  .static-map-area {
    position: relative;
    background-color: #f6f6f6;
    height: 100%;
    padding: 0 16px;
    border-radius: 16px;
    @media (min-width: 1200px) {
      padding: 60px 160px;
      border-radius: 36px;
    }
  }

  .static-map-img-box {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    @media (min-width: 1200px) {
      max-width: 900px;
      margin: 0 auto;
    }

    img {
      width: 100%;
      height: 100%;
    }

    @media (max-width: 768px) {
      .map-img {
        width: 100%;
        height: 100%;
        padding-top: 114px;
        padding-bottom: 114px;
      }
    }

    .react-transform-wrapper {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .reset-btn {
    position: absolute;
    right: 16px;
    bottom: 16px;
    display: inline-block;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(9px);
    border-radius: 40px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: inline-block;
      width: 40px;
      height: 40px;
      background-image: url('../../../public/images/ico-reset.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    &.is-active {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  .static-map-list {
    display: grid;
    background: #fff;
    border-radius: 10px;
    padding: 16px 24px 24px;
    margin-top: 12px;
    @media (min-width: 1200px) {
      background: #f6f6f6;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 24px;
      padding: 8px 24px;
      border-radius: 16px;
      margin-top: 28px;
    }
    & > div {
      display: flex;
      align-items: center;
      color: #535353;
      gap: 14px;
      padding: 0 12px;
      font-family: 'samsungsharpsans-bold', sans-serif;
      @media (min-width: 1200px) {
        gap: 16px;
        height: 80px;
        padding: 0 16px;
      }
      svg {
        margin-left: auto;
      }
    }

    & > div {
      padding: 16px 0;
      border-bottom: 2px solid #f0f0f0;
      &:last-of-type {
        border-bottom: 0;
      }
      @media (min-width: 1200px) {
        padding: 0;
        margin-bottom: initial;
        border-bottom: 2px solid #dfdfdf;
        &.border-none {
          border-bottom: 0;
        }
      }
    }

    .list-label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 6px;
      background: #535353;
      font-family: inherit;
      font-size: 12px;
      color: #fff;
      @media (min-width: 1200px) {
        width: 30px;
        height: 30px;
      }
    }
    .list-icon {
      width: 28px;
      @media (min-width: 1200px) {
        width: 30px;
      }
      svg {
        width: 100%;
      }
    }
    .list-text {
      font-size: 16px;
      line-height: 20px;
      @media (min-width: 1200px) {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}

.static-map-section.type02 {
  .static-map-title {
    margin-bottom: 20px;
  }
  @media (min-width: 1200px) {
    .static-map-title {
      margin-bottom: 40px;
    }
    .static-map-container {
      padding: 52px 40px 32px 40px;
      border-radius: 24px;
      background: #f6f6f6;
      max-width: 1120px;
    }
    .static-map-area {
      padding: 20px 112px;
    }
  }

  @media (max-width: 768px) {
    margin-top: 60px;
  }
  @media (max-width: 1199px) {
  }
}
