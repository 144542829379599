/* slick-slider, slick-arrow */
.TechSquareSlide {
  /*  Popup 내 스타일 */
  &.is-popup {
    border-radius: 0;
    overflow: visible;

    .slick-dots {
      bottom: -34px;
    }

    .slick-list {
      overflow: hidden;
    }

    .slick-slider {
      .slick-prev {
        left: -19px;
        &:before {
          background-position: 14px center;
          opacity: 1;
        }
      }

      .slick-next {
        right: -19px;
        &:before {
          background-position: 17px center;
          opacity: 1;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    &.is-popup {
      .slick-dots {
        bottom: -18px;
      }
      .slick-list {
        overflow: hidden;
      }

      .slick-slider {
        .slick-prev,
        .slick-next {
          visibility: visible;
        }

        .slick-prev {
          width: 24px;
          height: 24px;
          left: -11px;
          &:before {
            background-position: 8px center;
            background-size: 6px 11px;
            opacity: 1;
          }
        }

        .slick-next {
          width: 24px;
          height: 24px;
          right: -11px;
          &:before {
            background-position: 10px center;
            background-size: 6px 11px;
            opacity: 1;
          }
        }
      }
    }
  }

  .slick-slide {
    position: relative;
    margin: 0;
  }

  .slick-list {
    margin: 0;
    height: 100%;
  }

  .slick-track {
    display: flex;
    height: 100%;
    line-height: 0;
  }

  .slick-slider {
    position: relative;
    text-align: center;
    height: 100%;

    .slick-prev,
    .slick-next {
      width: 40px;
      height: 40px;
      background: rgba(0, 0, 0, 0.2) !important;
      border-radius: 50%;
      z-index: 2;
      transition: 0.4s !important;
    }

    .slick-prev {
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
      transition: 0.2s;

      &.slick-disabled {
        opacity: 0;
        visibility: hidden;
        &:before {
          opacity: 0;
          visibility: hidden;
        }
        &:hover {
          opacity: 0;
          visibility: hidden;
        }
      }

      &:before {
        opacity: 0.8;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background-image: url('../../../public/images/ico-slide-arrow-prev-medium.svg');
        background-size: 8px 14px;
        background-repeat: no-repeat;
        background-position: center center;
        transition: 0.2s;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.4) !important;
      }
    }

    .slick-next {
      top: 50%;
      right: 24px;
      transform: translateY(-50%);

      &.slick-disabled {
        opacity: 0;
        visibility: hidden;
        &:before {
          opacity: 0;
          visibility: hidden;
        }
        &:hover {
          opacity: 0;
          visibility: hidden;
        }
      }

      &:before {
        opacity: 0.8;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background-image: url('../../../public/images/ico-slide-arrow-next-medium.svg');
        background-size: 8px 14px;
        background-repeat: no-repeat;
        background-position: center center;
        transition: 0.2s;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.4) !important;
      }
    }

    @media screen and (max-width: 1024px) {
      .slick-slider {
        text-align: center;
      }
    }

    @media screen and (max-width: 768px) {
      .slick-prev,
      .slick-next {
        visibility: hidden;
      }
    }
  }

  /* slick dots */
  .slick-dots {
    bottom: 16px;
  }

  .slick-dots li {
    width: 8px;
    height: 8px;
    margin: 0 10px;

    + li {
      margin-left: 0;
    }

    &.slick-active {
      margin-right: 10px;
    }

    @media screen and (max-width: 768px) {
      width: 6px;
      height: 6px;
      margin: 0 8px;

      &.slick-active {
        margin-right: 8px;
      }
    }
  }

  .slick-dots li button:before {
    width: 8px !important;
    height: 8px !important;
    font-size: 0;
    background-color: #d4dae2;
    border-radius: 100%;
    opacity: 1;
    transition: width 0.3s;

    @media screen and (max-width: 768px) {
      width: 6px !important;
      height: 6px !important;
      margin: 0 12px;
    }
  }

  .slick-dots li.slick-active button:before {
    width: 8px;
    border-radius: 5px;
    background-color: #4387f7;
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    .slick-dots {
      left: 0;
    }

    .slick-dots li {
      width: 6px;
      height: 6px;
      // margin: 0 8px;
      margin-left: 0;

      &.slick-active {
        margin-right: 8px;
        margin-left: 0;
      }
    }

    .slick-dots li button {
      width: 6px !important;
      height: 6px !important;
      padding: 0 !important;
    }

    .slick-dots li button:before {
      width: 6px;
      height: 6px;
      margin: 0;
    }

    .slick-dots li.slick-active button:before {
      width: 6px;
      height: 6px;
      margin-left: 0;
    }
  }
}
