.eventmap_slide {
  /* slick slide space */
  .slick-slide {
    // margin: 0 !important;

    &:first-child {
      margin-left: 0;
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:not(.slick-current) {
      /* Make slides other than the current one translucent */
      opacity: 0.4;
    }
  }
}

.eventmap_slide {
  .slick-list {
    margin: 0 -5px;

    @media screen and (max-width: 768px) {
      overflow: visible;
      margin: 0;

      .slick-track {
        padding-right: 0 !important;
      }
    }
  }

  .slick-track {
    display: flex;
  }
}

/* slick-slider, slick-arrow */
.eventmap_slide {
  .slick-slider {
    position: relative;
    text-align: center;
    padding-bottom: 37px;
    margin-bottom: 0px;

    .slick-prev,
    .slick-next {
      width: 48px;
      height: 48px;
      background: rgba(0, 0, 0, 0.2) !important;
      border-radius: 50%;
      z-index: 2;
      transition: 0.4s !important;
    }

    .slick-prev {
      top: 50%;
      transform: translateY(-50%);
      left: -23px;
      transition: 0.2s;

      &.slick-disabled {
        opacity: 0;
        visibility: hidden;
        &:before {
          opacity: 0;
          visibility: hidden;
        }
        &:hover {
          opacity: 0;
          visibility: hidden;
        }
      }

      &:before {
        opacity: 0.8;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background-image: url('../../../public/images/ico-slide-arrow-prev-medium.svg');
        background-repeat: no-repeat;
        background-position: center center;
        transition: 0.2s;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.4) !important;
      }
    }

    .slick-next {
      top: 50%;
      right: -23px;
      transform: translateY(-50%);

      &.slick-disabled {
        opacity: 0;
        visibility: hidden;
        &:before {
          opacity: 0;
          visibility: hidden;
        }
        &:hover {
          opacity: 0;
          visibility: hidden;
        }
      }

      &:before {
        opacity: 0.8;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background-image: url('../../../public/images/ico-slide-arrow-next-medium.svg');
        background-repeat: no-repeat;
        background-position: center center;
        transition: 0.2s;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.4) !important;
      }
    }

    @media screen and (max-width: 1024px) {
      .slick-slider {
        text-align: center;
      }
    }

    @media screen and (max-width: 768px) {
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
  }

  /* slick dots */
  .slick-dots {
    bottom: 0;
    @media screen and (max-width: 768px) {
      bottom: -16px;
    }
  }

  .slick-dots li {
    width: 8px;
    height: 8px;
    margin: 0 20px;

    + li {
      margin-left: 0;
    }

    &.slick-active {
      margin-right: 20px;
    }

    @media screen and (max-width: 768px) {
      width: 6px;
      height: 6px;
      margin: 0 12px;

      + li {
        margin: 0 !important;
      }
    }
  }

  .slick-dots li button:before {
    width: 8px !important;
    height: 8px !important;
    font-size: 0;
    background-color: #d4dae2;
    border-radius: 100%;
    opacity: 1;
    transition: width 0.3s;

    @media screen and (max-width: 768px) {
      width: 6px !important;
      height: 6px !important;
      margin: 0 12px;
    }
  }

  .slick-dots li.slick-active button:before {
    width: 8px;
    border-radius: 5px;
    background-color: #2088fd;
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    .slick-dots {
      bottom: -16px !important;
      left: 0;
    }

    .slick-dots li {
      width: 6px;
      height: 6px;
      margin-left: 0;

      &.slick-active {
        margin-right: 12px;
        margin-left: 0;
      }
    }

    .slick-dots li button {
      width: 6px !important;
      height: 6px !important;
      padding: 0 !important;
    }

    .slick-dots li button:before {
      width: 6px;
      height: 6px;
      margin: 0;
    }

    .slick-dots li.slick-active button:before {
      width: 6px;
      height: 6px;
      margin-left: 0;
    }
  }
}
