.Button.black.calendarButton {
  position: relative;
  overflow: hidden;
  &:hover:not([disabled]),
  &:hover:not(.disabled) {
    background-color: #1e1e1e;
    transition: initial;
  }
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #2088fd;
    content: '';
    transform: scaleX(0);
    transform-origin: top right;
    transition: transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  }
  &:hover:after {
    transform: scaleX(1);
    transform-origin: top left;
  }
}

.item-typo.open {
  img {
    object-fit: contain;
  }
  img:first-child {
    padding-right: 125px;
    @media (max-width: 768px) {
      padding-right: 11.111vw;
    }
  }
  img:nth-child(2) {
    position: absolute;
    top: 50%;
    right: 1px;
    height: 170px;
    transform: translateY(-50%);
    @media (max-width: 768px) {
      height: 13.889vw;
    }
  }
  img:last-child {
    position: absolute;
    top: 0;
    right: 1px;
  }
}
.item-typo.build {
  transition-duration: 0.7s;
  margin-left: 265px;
  @media (max-width: 768px) {
    margin-left: 21.389vw;
  }
  img:nth-child(1) {
    position: absolute;
    left: -205px;
    transform: translateX(-100%);
    @media (max-width: 768px) {
      left: -16.111vw;
    }
  }
  img:nth-child(2) {
    position: absolute;
    top: 50%;
    right: 170px;
    transform: translateY(-50%);
    height: 180px;
    @media (max-width: 768px) {
      right: 13.889vw;
      height: 16.111vw;
    }
  }
  img:nth-child(3) {
    position: relative;
    @media (max-width: 768px) {
      margin-left: 1.111vw;
    }
  }
}

.item-typo.explore {
  line-height: 0 !important;
  .exploreBox {
    position: relative;
    height: 100%;
    background: #756bf1;
    aspect-ratio: 438 / 185;
    clip-path: url(#myClip);
    overflow: hidden;
    @media (max-width: 768px) {
      top: -1.111vw;
    }
    img {
      position: absolute;
      right: -20px;
      bottom: -190px;
      width: 254px;
      height: auto;
      @media (max-width: 768px) {
        right: -2.222vw;
        bottom: -17.222vw;
        width: 22.778vw;
      }
    }
  }
}

.item-common.active {
  .item-typo.open {
    img:nth-child(2) {
      padding-right: 144px;
      @media (max-width: 768px) {
        padding-right: 12.222vw;
      }
    }
  }
  .item-typo.build {
    img:nth-child(2) {
      right: 397px;
      @media (max-width: 768px) {
        right: 34.444vw;
      }
    }
  }
  .item-typo.explore {
    img {
      bottom: -120px;
      @media (max-width: 768px) {
        bottom: -10.556vw;
      }
    }
  }
}

.sloganTitle {
  .MainSectionTitleArea {
    padding: 0;
    & > div {
      margin-bottom: 0;
      @media (max-width: 768px) {
        margin-bottom: 12px;
      }
    }
    a {
      display: none;
    }
  }
}
