.activity-slide {
  .slick-slider {
    .slick-prev,
    .slick-next {
      display: none;
    }
  }
  @media screen and (max-width: 1024px) {
    .slick-list {
      width: 280px;
      overflow: visible;
    }
    .slick-slide {
      padding: 0 8px;
    }
    .slick-dots {
      bottom: -30px;
      left: 0;
    }

    .slick-dots li {
      width: 6px;
      height: 6px;
      margin: 0 4px;

      &.slick-active {
        margin-left: 4px;
      }
    }

    .slick-dots li button {
      width: 6px !important;
      height: 6px !important;
      padding: 0 !important;
    }

    .slick-dots li button:before {
      width: 6px;
      height: 6px;
      margin: 0;
    }

    .slick-dots li.slick-active button:before {
      width: 6px;
      height: 6px;
      margin-left: 0;
    }
  }
}
