$black: #1e1e1e;
$white: #fff;

@mixin button-color($color) {
  background-color: $color;

  &:hover:not([disabled]):not(.disabled) {
    @if ($color == $black) {
      background-color: #555;
    } @else if ($color == $white) {
      background-color: #ddd;
    }
  }

  &:active:not([disabled]):not(.disabled) {
    background-color: darken($color, 10%);
  }

  &.outline {
    color: #1e1e1e;
    background-color: transparent;
    border: 1px solid #1e1e1e;

    &:hover {
      background-color: #ddd;
    }
  }

  &:disabled {
    cursor: default;
    @if ($color == $black) {
      background-color: #ddd;
      color: #aaa;
    }
    @if ($color == $white) {
      background-color: transparent;
      color: #c4c4c4;
    }
  }

  &.disabled {
    cursor: default;
    @if ($color == $black) {
      background-color: #ddd;
      color: #aaa;
    }
    @if ($color == $white) {
      background-color: transparent;
      color: #c4c4c4;
    }
  }
}

.Button,
.Link,
.aLink {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 170px;
  color: white;
  border-radius: 36px;
  border: none;
  font-family: 'SamsungOne-700';
  cursor: pointer;
  outline: none;
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

  &.large {
    height: 4em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    font-size: 1.25em;
  }

  &.medium {
    height: 3.103em;
    padding-left: 2em;
    padding-right: 2em;
    font-size: 1.29em;
  }

  &.small {
    height: 1.75em;
    font-size: 16px;
    padding-left: 1em;
    padding-right: 1em;
  }

  &.xsmall {
    height: 3.45em;
    min-width: 172px;
  }

  height: 2.988em;
  padding-left: 1.3em;
  padding-right: 1.3em;
  font-size: 1.15em;

  &.black {
    @include button-color($black);
  }

  &.white {
    color: #1e1e1e;
    @include button-color($white);
  }

  &.whiteOpacity {
    color: #000;
    @include button-color($white);
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(16px);
  }

  &.border {
    color: #000;
    border: solid 1px #1e1e1e;
    @include button-color($white);
    &:disabled,
    &.disabled {
      opacity: 0.4;
      border: solid 1px #c4c4c4;
    }
  }

  &.borderTransparent {
    color: #000;
    border: solid 1px #1e1e1e;
    @include button-color($white);
    background-color: transparent;
    &:disabled,
    &.disabled {
      color: #c4c4c4;
      border-color: #c4c4c4;
      background-color: transparent;
    }
  }

  &.borderWhite {
    color: #fff;
    border: solid 1px #fff;
    &:hover:not([disabled]):not(.disabled) {
      background-color: rgba(255, 255, 255, 0.1);
    }
    &:disabled,
    &.disabled {
      opacity: 0.4;
      cursor: default;
    }
  }

  &.borderGray {
    color: #000;
    border: solid 1px #dcdcdc;
    &:hover:not([disabled]):not(.disabled) {
      background-color: rgba(54, 54, 54, 0.1);
    }
    &:disabled,
    &.disabled {
      opacity: 0.4;
      cursor: default;
    }
  }

  & + & {
    margin-left: 1em;
  }

  &.fullWidth {
    width: 100%;
    min-width: 0;
    margin-left: 0;
    justify-content: center;

    & + & {
      margin-left: 0;
      margin-top: 1em;
    }
  }

  &.none {
    display: none !important;
  }

  @media screen and (max-width: 768px) {
    min-width: 180px;

    &.medium {
      min-width: 128px;
      height: 2.998em;
      padding: 0 20px;
      font-size: 1.149em;
    }

    &.xsmall {
      min-width: 144px;
      height: 3em;
    }
  }
}
