.MainSessionCard {
  &:nth-of-type(1) {
    grid-column: span 2;
    background-color: #ffae34;
    color: #fff;
    .CardBg {
      span:nth-of-type(1) {
        right: 183px;
        bottom: 132px;
        width: 152px;
        height: 145px;
        background-image: url('../../../public/images/main-session-card-bg-11.svg');
      }
      span:nth-of-type(2) {
        right: 179px;
        bottom: -7px;
        width: 156px;
        height: 116px;
        background-image: url('../../../public/images/main-session-card-bg-12-1.svg');
      }
      span:nth-of-type(3) {
        right: 100px;
        bottom: -7px;
        width: 71px;
        height: 284px;
        background-image: url('../../../public/images/main-session-card-bg-13.svg');
      }
      span:nth-of-type(4) {
        bottom: -6px;
        width: 95px;
        height: 283px;
        background-image: url('../../../public/images/main-session-card-bg-14.svg');
      }
      span:nth-of-type(5) {
        width: 295px;
        height: 226px;
        background-image: url('../../../public/images/main-session-card-bg-15.png');
      }
    }
    @media screen and (max-width: 768px) {
      .CardBg:before {
        width: 160px;
        height: 133px;
        background-image: url('../../../public/images/main-session-card-bg-1.s.png');
      }
    }
    @media screen and (min-width: 769px) {
      &:hover {
        .CardBg {
          background-color: #ff9500;

          span:nth-of-type(1) {
            right: 253px;
            bottom: 207px;
            background-image: url('../../../public/images/main-session-card-bg-11.on.svg');
          }

          span:nth-of-type(2) {
            right: 348px;
            bottom: -3px;
            background-image: url('../../../public/images/main-session-card-bg-12.on-1.svg');
          }

          span:nth-of-type(3) {
            right: 182px;
            bottom: -108px;
            background-image: url('../../../public/images/main-session-card-bg-13.on.svg');
          }

          span:nth-of-type(4) {
            bottom: 176px;
            background-image: url('../../../public/images/main-session-card-bg-14.on.svg');
          }
        }
      }
    }
  }

  &:nth-of-type(2) {
    background-color: #756bf1;
    color: #fff;
    .CardBg {
      span:nth-of-type(1) {
        right: 153px;
        width: 108px;
        height: 275px;
        background-image: url('../../../public/images/main-session-card-bg-21.svg');
      }
      span:nth-of-type(2) {
        right: -15px;
        width: 168px;
        height: 275px;
        background-image: url('../../../public/images/main-session-card-bg-22.svg');
      }
    }
    @media screen and (max-width: 768px) {
      .CardBg:before {
        width: 107px;
        height: 134px;
        background-image: url('../../../public/images/main-session-card-bg-2.s.svg');
      }
    }
    @media screen and (min-width: 769px) {
      &:hover {
        .CardBg {
          background-color: #685ff1;

          span:nth-of-type(1) {
            right: 243px;
            bottom: -85px;
            background-image: url('../../../public/images/main-session-card-bg-21.on.svg');
          }

          span:nth-of-type(2) {
            right: -23px;
            bottom: 160px;
            background-image: url('../../../public/images/main-session-card-bg-22.on.svg');
          }
        }
      }
    }
  }

  &:nth-of-type(3) {
    .CardInner {
      height: 100%;
    }
    grid-row: span 2;
    background-color: #2088fd;
    color: #fff;
    .CardBg {
      span:nth-of-type(1) {
        right: 88px;
        bottom: -19px;
        width: 187px;
        height: 294px;
        background-image: url('../../../public/images/main-session-card-bg-41-2.svg');
      }
      span:nth-of-type(2) {
        right: -17px;
        bottom: -19px;
        width: 123px;
        height: 294px;
        background-image: url('../../../public/images/main-session-card-bg-42-2.svg');
      }
    }
    @media screen and (max-width: 768px) {
      .CardInner {
        height: 200px;
      }
      .CardBg:before {
        width: 123px;
        height: 135px;
        background-image: url('../../../public/images/main-session-card-bg-4.s-2.svg');
      }
    }
    @media screen and (min-width: 769px) {
      &:hover {
        .CardBg {
          background-color: #2088fd;

          span:nth-of-type(1) {
            right: 184px;
            background-image: url('../../../public/images/main-session-card-bg-41.on-2.svg');
          }

          span:nth-of-type(2) {
            right: -17px;
            bottom: 210px;
            background-image: url('../../../public/images/main-session-card-bg-42.on-2.svg');
          }
        }
      }
    }
  }

  &:nth-of-type(4) {
    grid-column: span 2;
    .CardBg {
      span:nth-of-type(1) {
        right: 228px;
        bottom: -72px;
        width: 102px;
        height: 273px;
        background-image: url('../../../public/images/main-session-card-bg-51.svg');
      }
      span:nth-of-type(2) {
        right: 114px;
        bottom: 63px;
        width: 133px;
        height: 138px;
        background-image: url('../../../public/images/main-session-card-bg-52.svg');
      }
      span:nth-of-type(3) {
        right: 104px;
        bottom: -60px;
        width: 152px;
        height: 103px;
        background-image: url('../../../public/images/main-session-card-bg-53.svg');
      }
      span:nth-of-type(4) {
        width: 442px;
        height: 257px;
        background-image: url('../../../public/images/main-session-card-bg-54-1.png');
      }
    }
    @media screen and (max-width: 768px) {
      .Text {
        color: #000;
      }
      .CardBg:before {
        width: 190px;
        height: 124px;
        background-image: url('../../../public/images/main-session-card-bg-5.s.png');
      }
    }
    @media screen and (min-width: 769px) {
      &:hover {
        .CardBg {
          background-color: #eaeaea;

          span:nth-of-type(1) {
            right: 438px;
            bottom: -62px;
            background-image: url('../../../public/images/main-session-card-bg-51.on.svg');
          }

          span:nth-of-type(2) {
            right: -21px;
            bottom: 228px;
            background-image: url('../../../public/images/main-session-card-bg-52.on.svg');
          }

          span:nth-of-type(3) {
            right: 37px;
            bottom: 0;
            background-image: url('../../../public/images/main-session-card-bg-53.on.svg');
          }
        }
      }
    }
  }

  &:nth-of-type(5) {
    grid-column: span 2;
    background-color: #10b77b;
    color: #fff;
    .CardBg {
      span:nth-of-type(1) {
        right: 142px;
        bottom: -10px;
        width: 113px;
        height: 294px;
        background-image: url('../../../public/images/main-session-card-bg-61-v2.svg');
      }
      span:nth-of-type(2) {
        right: -24px;
        bottom: 121px;
        width: 155px;
        height: 163px;
        background-image: url('../../../public/images/main-session-card-bg-62-v2.svg');
      }
      span:nth-of-type(3) {
        right: -16px;
        bottom: -24px;
        width: 158px;
        height: 139px;
        background-image: url('../../../public/images/main-session-card-bg-63-v2.svg');
      }
    }
    @media screen and (max-width: 768px) {
      .CardBg:before {
        right: -1px;
        bottom: -1px;
        width: 115px;
        height: 130px;
        background-image: url('../../../public/images/main-session-card-bg-6.s-v2.svg');
      }
    }
    @media screen and (min-width: 769px) {
      &:hover {
        .CardBg {
          background-color: #10b77b;

          span:nth-of-type(1) {
            right: 314px;
            bottom: -58px;
            background-image: url('../../../public/images/main-session-card-bg-61.on-v2.svg');
          }

          span:nth-of-type(2) {
            right: -24px;
            bottom: 256px;
            background-image: url('../../../public/images/main-session-card-bg-62.on-v2.svg');
          }

          span:nth-of-type(3) {
            right: -17px;
            bottom: -37px;
            background-image: url('../../../public/images/main-session-card-bg-63.on-v2.svg');
          }
        }
      }
    }
  }
}
