/* slick slide space */
.slick-slider {
  width: 100%;
  padding-bottom: 0;
  // overflow: hidden !important;
}

.slick-slide {
  margin: 0 12px;
  @media screen and (max-width: 1024px) {
    margin: 0 8px;
  }

  @media screen and (max-width: 768px) {
    // margin: 0;
  }
}

.slick-list {
  margin: 0 -12px;
  @media screen and (max-width: 1024px) {
    margin: 0 -8px;
  }
}

.slick-track {
  display: flex;
  margin-left: 0;
}

/* slick-slider, slick-arrow */
.timestampe {
  .slick-list {
    overflow: hidden;
  }

  .slick-slider {
    position: relative;
    text-align: center;

    .slick-prev,
    .slick-next {
      width: 40px;
      height: 40px;
      border: 1px solid #dcdcdc;
      border-radius: 8px;
      background: transparent !important;
      z-index: 1;
    }

    .slick-prev {
      top: -40px;
      left: auto;
      right: 48px;

      &:before {
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background-image: url('../../../public/images/timestamp-prev.svg');
        background-repeat: no-repeat;
        background-position: center center;
        opacity: 1;
        transition: 0.2s;
        transform: rotate(180deg);
      }

      &.slick-disabled {
        &::before {
          opacity: 0.25;
        }
      }
    }

    .slick-next {
      top: -40px;
      left: auto;
      right: 0;

      &:before {
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background-image: url('../../../public/images/timestamp-prev.svg');
        background-repeat: no-repeat;
        background-position: center center;
        opacity: 1;
        transition: 0.2s;
      }

      &.slick-disabled {
        &::before {
          opacity: 0.25;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      .slick-slider {
        text-align: center;
      }
    }

    @media screen and (max-width: 768px) {
      .slick-prev {
        top: -46px;
        &::before {
          background-size: inherit;
        }
      }

      .slick-next {
        top: -46px;
        &::before {
          background-size: inherit;
        }
      }
    }
  }
}

/* slick dots */
.slick-dots {
  bottom: 0;
}

.slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0 14px;

  + li {
    margin-left: 0;
  }

  &.slick-active {
    margin-right: 36px;
  }
}

.slick-dots li button:before {
  width: 10px;
  height: 10px;
  font-size: 0;
  background-color: #d4dae2;
  border-radius: 100%;
  opacity: 1;
  transition: width 0.3s;
}

.slick-dots li.slick-active button:before {
  width: 32px;
  border-radius: 5px;
  background-color: #4387f7;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .slick-dots {
    bottom: 0;
  }

  .slick-dots li {
    width: 6px;
    height: 5px;
    margin: 0 4px;

    &.slick-active {
      margin-right: 24px;
    }
  }

  .slick-dots li button {
    width: 6px;
    height: 5px;
  }

  .slick-dots li button:before {
    width: 6px;
    height: 5px;
  }

  .slick-dots li.slick-active button:before {
    width: 20px;
    height: 5px;
  }
}
